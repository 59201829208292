.tabs__head{
  display: flex;
  flex-wrap: wrap;
}
.tab__link{
  margin-right: 27px;
  margin-bottom: 12px;
  @include fontRegular();
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: .3px;
  color: $baseGray;
  text-transform: uppercase;
  padding-bottom: 1px;
  cursor: pointer;
  transition: color .3s, border-bottom-color .3s;
  &:hover,
  &.active{
    color: $textDark;
    border-bottom: 1px solid rgba(160, 158, 158, 0.7);
  }
  &:last-child{
    margin-right: 0;
  }
}
.tab__content{
  display: none;
  .headline2{
    margin: 50px 0 35px 0;
  }
}