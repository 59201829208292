.wrapper{
  width: $wrapperWidth;
  max-width: 100%;
  padding: 0 40px;
  margin: 0 auto;
  height: 100%;
}
.page.two_col .section__content{
  padding-left: calc((100% - 1440px) / 2 + 460px);
}
.page{
  min-height: 100vh;
  &.two_col{
    padding-top: 180px;
    .section__content{
      padding-left: 500px;
      padding-top: 0;
      padding-bottom: 55px;
      height: auto;
    }
  }
  &:not(.page_index){
    &.two_col {
      .section__content {
        padding-left: 500px;
      }
    }
    .section__content{
      justify-content: flex-start;
      padding-left: 160px;
      padding-right: 160px;
    }
  }
}
.page__title{
  margin-bottom: 90px;
  &_for-desc{
    margin-bottom: 30px;
  }
}
.page__desc{
  @include fontRegular;
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 75px;
}
.full-width{
  width: 100%;
}
.side{
  position: fixed;
  top: 160px;
  left: calc((100% - 1440px) / 2 + 180px);
  width: 280px;
  z-index: 2;
  .page__title{
    margin-bottom: 42px;
  }
}
.side__content{
  position: sticky;
  top: 0;
}
.row{
  display: flex;
}
.section{
  min-height: 100%;
}
.section__title{
  margin-bottom: 40px;
}
.section__subtitle{
  margin-bottom: 30px;
}
.section__row{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.section__head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  .headline1{
    margin-bottom: 0;
  }
}
.section__content{
  position: relative;
  width: 1440px;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 160px 80px 100px 80px;
}
.section__background{
  position: absolute;
  top: 0;
  right: 0;
}
.section__left{
  width: 50%;
}
.section__right{
  width: 50%;
}
.section__bottom{
  flex: 0 0 auto;
}

#circle_bg{
  ellipse{
    box-shadow: 1px 1px 10px 10px rgba(0, 0, 0, .3);
  }
}