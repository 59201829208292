.section_about{
  position: relative;
  background-image: url("../images/bg/rect@1x.png");
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url("../images/bg/rect@2x.png");
  }
  background-repeat: no-repeat;
  background-position: top 150px right;

  .section__row {
    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .section__left {
      @media (max-width: 1024px) {
        order: 2;
        margin-top: 30px;
      }
    }
  }

  .slider__item-visual {
    height: 421px;

    @media (max-width: 400px) {
      height: 164px;
    }
  }

}
.section_about__title{
  margin-bottom: 30px;
}
.section_about__subtitle{
  @include fontForum;
  font-size: 3.2rem;
  line-height: 1;
  margin-bottom: 20px;
}
.section_about__desc{
  margin-bottom: 40px;
  line-height: 2.6rem;
}
.section_about__action {
  z-index: 11;
  position: relative;
}
