.accordion{

}
.accordion__list{

}
.accordion__item{
  width: 530px;
  max-width: 100%;
  border-bottom: 1px solid #A09E9E;
  &.active{
    width: 100%;
    .accordion__item-head{
      &:after{
        transform: rotate(90deg);
      }
    }
    .accordion__item-body{
      width: 530px;
      max-width: 100%;
    }
  }
}
.accordion__item-head{
  display: inline-block;
  position: relative;
  @include fontForum;
  font-size: 2.4rem;
  line-height: 2.6rem;
  padding: 25px 80px 22px 0;
  cursor: pointer;
  &:after{
    content: '';
    font-size: 0;
    line-height: 0;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 24px;
    top: calc(50% - 20px);
    transition: transform .3s;
    background-image: url($src_img + "svg/accordion__arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}
.accordion__item-body{
  display: none;
  padding-bottom: 30px;
}