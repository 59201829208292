.fp-section{
  .main-screen__title{
    transform: translateY(-40px);
    opacity: 0;
    transition: opacity .7s .7s ease-out, transform .7s .7s ease-out;
  }
  .main-screen__text{
    transform: translateY(-30px);
    opacity: 0;
    transition: opacity .7s .7s ease-out, transform .7s .7s ease-out;
  }
  &.active{
    .main-screen__title,
    .main-screen__text{
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.section_main-screen{
  position: relative;
  background-image: url("../images/bg/main-screen@1x.png");
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) {
    background-image: url("../images/bg/main-screen@2x.png");
  }
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .section__content{
    height: 100%;
  }
  padding-bottom: 157px;
  .section__left{
    transition: opacity .3s .3s, visibility .3s .3s;
    &.hidden{
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s, visibility .3s;
    }
  }
}
.main-screen__title{
  @include fontForum;
  font-size: 8rem;
  line-height: initial;
  margin-bottom: 40px;
}
.main-screen__text{
  @include fontRegular;
  font-size: 2.4rem;
  line-height: 3.3rem;
}
.main-screen__visual{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 640px) {
    height: 0;
  }
}
.main-screen__visual-wrap{
  width: 487px;
  height: 489px;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  color: rgba(141, 141, 141, 1);
  //transition: width 1.2s, height 1.2s, transform 1.2s, color 1.2s, top 1.2s, left 1.2s;
}
.main-screen__bottom{
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity .3s .3s, visibility .3s .3s;
  &.hidden{
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;
  }
}
.main-screen__contacts{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 47px;
}
a.main-screen__mail{

}
.main-screen__address{
  text-align: center;
}
.main-screen__socials{

}
.main-screen__scroll{
  position: absolute;
  right: 40px;
  top: 50%;
  display: flex;
  align-items: center;
  transform: translateX(50%) rotate(90deg);
}
.main-screen__scroll-text{
  @include fontRegular;
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: .3em;
  color: rgba(160, 158, 158, 1);
}
.main-screen__scroll-mouse{
  position: relative;
  display: block;
  width: 35px;
  height: 21px;
  border: 1px solid rgba(160, 158, 158, 1);
  border-radius: 10px;
  margin-left: 23px;
  &:before{
    content: '';
    display: block;
    width: 7px;
    height: 1px;
    background-color: rgba(160, 158, 158, 1);
    position: absolute;
    top: calc(50% - 1px);
    left: 9px;
    animation: linear infinite alternate;
    animation-name: mouseScroll;
    animation-duration: 1s;
  }
  &:after{
    content: '';
    font-size: 0;
    line-height: 0;
    width: 5px;
    height: 10px;
    position: absolute;
    right: -20px;
    top: calc(50% - 6px);
    background-image: url(../images/svg/accordion__arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
}

@-webkit-keyframes mouseScroll {
  0% { left: 9px;}
  50%{ left : 14px;}
  100%{ left: 9px;}
}
.section_about{
  .section__left{
    padding-right: 60px;
  }
}
.section_about__slider{
  position: relative;
  .slider{
    overflow: visible;
  }
}
.slider__item{
  &.swiper-slide-active{
    .slider__item-visual{
      box-shadow: -4px 6px 25px 0 rgba(0, 0, 0, .15);
      transition: box-shadow .3s 1s ease-out;
    }
    .slider__item-title{
      opacity: 1;
      transition: opacity .3s 1s ease-out;

      @media (max-width: 640px) {
        opacity: 0;
      }
    }
  }
}
.slider__item-visual{
  position: relative;
  font-size: 0;
  line-height: 0;
  height: 350px;
  overflow: hidden;
}
.slide-inner{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 50px;
  background-size: cover;
  background-position: center;
}
.slider__item-title{
  @include fontRegular;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #A09E9E;
  margin-top: 32px;
  padding-right: 100px;
  opacity: 0;
  transition: opacity .1s;
}
.slider__controls{
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 10;
}
.slider__control{
  display: flex;
  + .slider__control{
    margin-left: 22px;
  }
}
.slider__pagination{
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #A09E9E;
}
.slider__button{
  line-height: 0;
  font-size: 0;
  color: #A09E9E;
  cursor: pointer;
  transition: color .3s ease-out;
  &_prev{
    transform: rotate(180deg);
  }
  &_next{

  }
  + .slider__button{
    margin-left: 45px;
  }
  &:hover{
    color: $textDark;
  }
}
