@import '../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min';
@import '../../node_modules/fullpage.js/dist/fullpage.min';
@import '../../node_modules/swiper/dist/css/swiper.min';
@import '../../node_modules/jquery-nice-select/scss/nice-select';

@import "./dist/config";
@import "./dist/mixins";
@import "./dist/typography";
@import "./dist/layout";

*{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}
html,
body{
  width: 100%;
  height: 100%;
  &.overflow-hidden{
    height: 100vh;
    overflow: hidden;
  }
}
html{
  font-size: 62.5%;
}
body{
  @include fontRegular;
  font-size: 1.6rem;
  line-height: 2;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}
#fp-nav{
  &.fp-left{
    left: 18px;
  }
  ul{
    li{
      margin: 22px;
      padding: 0;
      a{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid rgba(160, 158, 158, 1);
        transition: background-color .3s ease-out;
        &.active{
          background-color: rgba(160, 158, 158, .48);
        }
        span{
          display: none;
        }
      }
      &:before{
        display: none;
      }
      .fp-tooltip{
        width: auto;
        @include fontRegular;
        font-size: 1.4rem;
        color: #A09E9E;
        top: -5px;
        opacity: 0;
        visibility: hidden;
        transform: translateX(15px);
        transition: opacity .3s ease-out, transform .3s ease-out, visibility .3s ease-out;
      }
      &:hover{
        .fp-tooltip{
          opacity: 1;
          visibility: visible;
          transform: translateX(0);
          transition: opacity .3s ease-out, transform .3s ease-out, visibility .3s ease-out;
        }
      }
    }
  }
}
#fp-nav ul li:hover .fp-tooltip, #fp-nav.fp-show-active a.active+.fp-tooltip{
  transition: opacity .3s ease-out, transform .3s ease-out, visibility .3s ease-out;
}


@import "components/menu";
@import "components/buttons";
@import "components/forms";
@import "components/cards";
@import "components/page__nav";
@import "components/documents";
@import "components/tabs";
@import "components/accordion";
@import "components/popups";
@import "components/select";

@import "blocks/header";
@import "blocks/footer";
@import "blocks/main-screen";
@import "blocks/section_about";
@import "blocks/section_services";

@import "pages/service-detail";
@import "pages/doctor-detail";
@import "pages/about";
@import "pages/contacts";

@import "dist/media";