.btn{
  display: inline-block;
  min-width: 170px;
  @include fontRegular;
  font-size: 1.8rem;
  line-height: 2.1rem;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid rgba(0, 0, 0, .6);
  background-color: rgb(255, 255, 255);
  padding: 13px 20px 14px 20px;
  transition: background-color .3s;
  cursor: pointer;
  &:hover{
    background-color: rgba(160, 158, 158, .2);
  }
  &:active{
    background-color: rgba(133, 131, 131, .4);
  }
  &:disabled,
  &_disabled{
    opacity: .5;
    cursor: pointer;
    pointer-events: none;
  }
}