.page_about{
  position: relative;
  background-image: url("../images/bg/bubbles@1x.png");
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url("../images/bg/bubbles@2x.png");
  }
  background-repeat: no-repeat;
  background-position: top 150px right 100px;
  background-attachment: fixed;
  .accordion{
    width: 100%;
  }
}
.section_about-slider{
  padding-top: 50px;
  background-color: #F5F5F5;
  margin-bottom: 50px;

  .slider__item-visual {
    height: 421px;
  }
}
.section_about-review{

}

.about-reviews{

}
.about-reviews__nav{
  display: flex;
  margin-bottom: 60px;
}
.about-reviews__nav-link{
  @include fontRegular;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: rgba(0, 0, 0, .7);
}
.about-reviews__list{

}
.about-review{
  + .about-review{
    margin-top: 50px;
  }
}
.about-review__head{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px;
}
.about-review__name{
  @include fontRegular;
  font-size: 2rem;
  line-height: 4.6rem;
}
.about-review__date{
  @include fontRegular;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #A09E9E;
  margin-left: 20px;
}
.about-review__body{

}
.about-review__stars{
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 20px;
  &:not(.disabled){
    .about-review__star{
      &:hover{
        color: #A09E9E;
        & ~ .about-review__star{
          color: #A09E9E;
        }
      }
    }
  }
}
.about-review__star{
  color: #C3C2C2;
  &.active{
    color: #A09E9E;
    & ~ .about-review__star{
      color: #A09E9E;
    }
  }
  + .about-review__star{
    padding-right: 8px;
  }
}
.about-review__text{
  @include fontRegular;
  font-size: 1.5rem;
  line-height: 2.4rem;
}

.about-vacance__head{
  @include fontRegular;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-bottom: 30px;
}
.about-review__actions{
  margin-top: 40px;
}
.about-review__action{
  .review-see-all{
    @include fontRegular;
    font-size: 1.8rem;
    line-height: 2.2rem;
    padding-bottom: 2px;
    border-bottom: 1px solid #A09E9E;
    color: $textDark;
    text-transform: uppercase;
    transition: border .3s ease-out;
    &:hover{
      border-color: $textDark;
    }
  }
  + .about-review__action{
    padding-top: 30px;
    border-top: 1px solid #A09E9E;
    margin-top: 55px;
  }
}


.section_about-useful{

}
.about-useful__list{
  columns: 2;
  column-gap: 90px;
}
.about-useful__item{
  + .about-useful__item{
    margin-top: 26px;
  }
}
.about-useful__link{
  text-decoration: underline;
  @include fontRegular;
  font-size: 1.8rem;
  line-height: 2.4rem;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  transition: all .3s;

  &:hover {
    border-color: #000;
  }
}
.about-text-row{
  display: flex;
  align-items: flex-start;
  @media (max-width: 640px) {
    flex-direction: column;
  }
}
.about-text-col{
  + .about-text-col{
    margin-left: 50px;
    @media (max-width: 640px) {
      margin: 0 auto;
    }

  }
}
