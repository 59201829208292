.cards{
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  &_small{
    .card{
      //height: 340px;
    }
    .card__visual{
      img{
        max-width: 80%;
        max-height: 300px;
      }
    }
  }
  &_large{
    .card{
      height: 480px;
    }
  }
}
.card{
  position: relative;
  width: calc(100% / 3 - 30px);
  height: 400px;
  margin: 0 15px 40px 15px;
  background-color: rgba(249, 249, 249, 1);
  padding: 50px 40px 25% 40px;
  transform-style: preserve-3d;
  cursor: pointer;
}
.card__title{
  margin-bottom: 20px;
  transform: translateZ(20px)
}
.card__desc{
  @include fontRegular;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-bottom: 20px;
  transform: translateZ(25px)
}
.card__price{
  color: rgba(160, 158, 158, 1);
  transform: translateZ(10px)
}
.card__visual{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateZ(1px);
  display: flex;
  align-items: flex-end;
  img{
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 340px;
    width: auto;
    height: auto;
  }
}