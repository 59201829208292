.nice-select{
  width: 100%;
  max-width: 100%;
  border-radius: 0;
  border: 1px solid rgba(0,0,0,0.6);
  @include fontForum;
  font-size: 1.8rem;
  line-height: 40px;
  height: 42px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  float: none;
}
.nice-select .list{
  list-style: none;
  background-color: #FFFFFF;
  border-top: 1px solid rgba(0,0,0,0.6);;
  width: calc(100% + 2px);
  box-shadow: none;
  border-radius: 0;
  margin-top: 0;
  left: -1px;
  transform: none;
}
.nice-select .option{
  @include fontForum;
  font-size: 1.8rem;
  line-height: 40px;
  text-transform: initial;
  min-height: auto;
  border: 1px solid rgba(0,0,0,0.6);
  border-top: 0;
  &:before{
    display: none;
  }
  &.selected{
    font-weight: normal;
    display: none;
  }
  + .option{
    margin-top: 0;
  }
}
.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus{
  background-color: #ececec;
}