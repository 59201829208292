$wrapperWidth: 100%;
$src_img: '../images/';


// Colors map
$baseLight: #FFFFFF;
$baseDark: #000000;

$baseGray: #f5f5f5;
$baseGrayLight: #ececec;
$baseGrayDark: #c4c4c4;

$subColor: rgb(204, 46, 75);

$textLight: #FFFFFF;
$textDark: #000000;