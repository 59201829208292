.page_services{
  position: relative;
  background-image: url("../images/bg/rect@1x.png");
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url("../images/bg/rect@2x.png");
  }
  background-repeat: no-repeat;
  background-position: top 150px right;
  background-attachment: fixed;
}
.page_services-detail{
  min-height: 100%;
  position: relative;
  .page_services-detail-visual{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    opacity: .2;
    z-index: -1;
    img{
      max-width: 100%;
    }
  }
}
.service__back {
  margin-bottom: 20px;
  position: relative;
}
.service__link {
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    &:hover {
      span {
        &:after {
          width: 100%;
        }
      }
    }
  }

  svg {
    margin-right: 15px;
  }

  span {
    font-size: 1.8rem;
    line-height: 2.8rem;
    position: relative;
    display: block;

    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 0;
      bottom: 0;
      left: 0;
      background-color: #000;
      -webkit-transition: width .5s ease-out;
      transition: width .5s ease-out;
    }
  }
}
.service__prices{
  columns: 2;
  li{
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
    page-break-inside: avoid; /* Firefox */
    break-inside: avoid; /* IE 10+ */
  }
}
.service__price{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 70px;
  &_group{
    display: block;
    justify-content: initial;
    align-items: initial;
  }
}
.service__price-group-name{
  display: block;
  width: 100%;
  @include fontRegular;
  font-size: 1.7rem;
  line-height: 2.1rem;
  margin-bottom: 10px;
}
.service__price-group-list{
  .service__price-name,
  .service__price-value{
    font-size: 1.7rem;
    line-height: 1.8rem;
  }
}
.service__price-group-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  + .service__price-group-item{
    margin-top: 8px;
  }
}
.service__price-name{
  @include fontRegular;
  font-size: 1.7rem;
  line-height: 2.1rem;
}
.service__price-value{
  @include fontRegular;
  font-size: 1.7rem;
  line-height: 2.1rem;
  margin-left: 30px;
  flex: 0 0 auto;
}
.scroll-to-page{
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 435px;
  max-width: 100%;
  position: relative;
  color: rgba(0, 0, 0, .3);
  transition: color .3s ease-out;
  &:hover{
    color: rgba(0, 0, 0, 1);
  }
}

.scroll-to-page__text{
  @include fontForum;
  font-size: 2.6rem;
  line-height: 2.9rem;
}
.scroll-to-page__icon{
  svg{
    transform: rotate(-90deg);
  }
}
