.header{
  padding: 50px 0 40px 0;
  //background-color: rgba(255, 255, 255, .6);
  background-image: linear-gradient(to bottom, #ffffff 0%,rgba(255,255,255,0.6) 100%);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 110;
  transform: translateZ(100px);
}
.header__row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__logo{
  position: fixed;
  top: 30px;
  left: calc(50% - 35px);
  width: 70px;
  height: 68px;
  color: rgb(25, 25, 25);
  transition: all 1.2s ease-out;

  @media (max-width: 1366px) {
    transition: all .3s ease-out;
  }

  svg{
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}