.form{

}
.form__row{
  display: flex;
  .form__field{
    width: 50%;
    padding-right: 7px;
    + .form__field{
      margin-top: 0;
      padding-right: 0;
      padding-left: 7px;
    }
  }
}
.form__field,
.form__row{
  + .form__field,
  + .form__row{
    margin-top: 15px;
  }
}
.form__actions{
  display: flex;
  align-items: center;
  margin-top: 30px;
  .btn{
    height: 52px;
    flex: 0 0 200px;
  }
}
.form__confirmed{
  @include fontRegular;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #A09E9E;
  padding-left: 40px;
}

.form__input,
input[type="text"],
input[type="tel"],
input[type="number"],
input[type="search"],
input[type="email"]{
  width: 100%;
  border: 1px solid $baseGray;
  background-color: $baseGray;
  @include fontRegular;
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-overflow: ellipsis;
  padding: 10px 20px 13px 20px;
  transition: color .3s, background-color .3s, border-color .3s;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  @include placeholder {
    color: #A09E9E;
    transition: color .3s;
  }
  &:focus{
    border-color: rgba(230, 227, 227, .5);
    background-color: $baseLight;
    color: $textDark;
    @include placeholder {
      color: $textDark;
      transition: color .3s;
    }
  }
  &.error{
    border-color: $subColor;
    background-color: $textLight;
    @include placeholder {
      color: $textDark;
      transition: color .3s;
    }
  }
}
textarea{
  min-height: 100px;
  max-width: 100%;
}
.form__field_file{
  display: flex;
  align-items: flex-start;
  input[type=file]{
    display: none;
  }
  .form__input-text{
    font-size: 1.4rem;
    margin-left: 12px;
  }
}
.form__field_upload-block{
  display: flex;
  align-items: center;
  margin-right: 36px;
  cursor: pointer;
  flex: 0 0 auto;
}
.form__field_files-block{
  display: flex;
  align-items: flex-start;
}
.form__field_files-block{
  flex-wrap: wrap;
  @include fontRegular;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #A09E9E;
  transition: color .3s ease-out;
  &:hover{
    color: $textDark;
    .input-file__delete{
      &:after, &:before{
        background-color: $textDark;
      }
    }
  }
  .form__field_files-item{
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    &:last-child{
      margin-right: 0;
    }
  }
}
.input-file__delete{
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  &:before,
  &:after{
    content: '';
    width: 12px;
    height: 1px;
    background-color: #A09E9E;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: background-color .3s ease-out;
  }
  &:before{
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after{
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}