.footer{
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-color: $baseGrayLight;
  transform: translateY(100%) translateZ(50px);
  transition: transform .3s ease-out;
  &.active{
    transform: translateY(0) translateZ(50px);
  }
}
.footer__toggle{
  width: 130px;
  height: 24px;
  background-image: url($src_img + "svg/footer__toggle.svg");
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  &.active{
    .footer__toggle-dot{
      &:first-child{
        width: 20px;
        left: calc(50% - 20px);
      }
      &:last-child{
        width: 20px;
        right: calc(50% - 20px);
      }
    }
  }
}
.footer__toggle-dot{
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #474747;
  position: absolute;
  top: 10px;
  left: calc(50% - 2px);
  transition: width .3s, left .3s, right .3s;
  &:first-child{
    left: calc(50% - 10px);
  }
  &:last-child{
    left: auto;
    right: calc(50% - 10px);
  }
}
.footer__row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0 25px;
}
.footer__contacts{
  line-height: 1;
  width: 200px;
  max-width: 100%;
}
.footer__contacts-phone{
  margin-bottom: 20px;
  a{
    display: inline-block;
    @include fontRegular;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}
.footer__contacts-mail{
  margin-bottom: 10px;
  a{
    display: inline-block;
    @include fontRegular;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}
.footer__contacts-address{
  a{
    display: inline-block;
    @include fontRegular;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}
.footer__center{
  text-align: center;
}
.footer__nav{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.footer__nav-item{
  line-height: 1;
  + .footer__nav-item{
    margin-left: 20px;
  }
}
.footer__nav-link{
  @include fontForum;
  font-size: 1.4rem;
  line-height: 1;
}
.footer__socials{
  line-height: 1;
  margin-bottom: 18px;
  color: #858383;
}
.footer__social-link{
  @include fontRegular;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #858383;
}

.footer__disclamer{
  @include fontLight;
  font-size: 1.8rem;
  line-height: 2.6rem;
  text-transform: uppercase;
  color: #C3C2C2;
}
.footer__links{
  width: 200px;
}
.footer__documents{
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer__documents-link{
  @include fontRegular;
  font-size: 1.2rem;
  line-height: 1.7rem;
  border-bottom: 1px solid rgba(160, 158, 158, .2);
  color: #858383;
  + .footer__documents-link{
    margin-top: 5px;
  }
}
.footer__creators{

}
.footer__creator{
  @include fontRegular;
  font-size: 1.2rem;
  line-height: 1.6rem;
}