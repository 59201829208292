@media (max-width: 1440px) {
  .section__content{
    padding: 160px 110px 100px 110px;
  }
  //.page.two_col .section__content{
  //  padding-left: calc((100% - 1440px) / 2 + 460px);
  //}
  .page:not(.page_index).two_col .section__content{
    padding-left: calc((100% - 1440px) / 2 + 460px);
  }
}
@media (max-height: 830px){
  .menu{
    padding: 4%;
  }
  .nav__item{
    padding: 24px 0;
  }
  .menu-button_close{
    top: 10%;
  }
}
@media (max-width: 1366px) {
  .page:not(.page_index) {
    &.page_doctor-detail {
      .section__content,
      &.two_col .section__content {
        padding: 0;
      }
    }
  }
  .section_main-screen{
    padding-bottom: 0;
    .section__row{
      flex-direction: column-reverse;
      align-items: center;
      .section__left{
        text-align: center;
      }
    }
  }
  .section_services > .wrapper,
  .section_services .fp-scroller > .wrapper{
    height: auto;
  }
  //.section_about-slider{
  //  display: none;
  //}
  .main-screen__bottom{
    display: none;
  }
  .main-screen__visual-wrap{
    margin-bottom: 40px;
    text-align: center;
    svg{
      height: 400px;
    }
  }
  .page_doctor-detail{
    display: flex;
    flex-direction: column;
  }
  .doctor__info{
    width: 100%;
    height: 100%;
    padding-right: 0;
    .section__visual{
      right: 0;
      bottom: 100px;
      img {
        height: 480px;
      }
    }
  }
  .side{
    display: none;
  }
  .page{
    padding-bottom: 70px;
  }
  //.page:not(.page_index).two_col .section__content{
  //  padding-left: 0;
  //}
  //.page:not(.page_index) .section__content{
  //  padding: 160px 0 0 0;
  //}
  .menu{
    padding: 0;
  }
  .page_contacts{
    .section__content{
      margin-bottom: 50px;
    }
    .contacts__map{
      margin-bottom: -70px;
    }
  }
}
@media (max-width: 1024px) {
  .menu{
    .menu__content{
      flex-direction: column;
      overflow-y: auto;
    }
    .menu__left,
    .menu__right{
      flex: none;
      height: auto;
      padding: 45px 55px;
    }
    .menu__left{
      padding-top: 100px;
      min-height: 60%;
    }
    .menu__right{
      min-height: 40%;
    }
    .menu-info__group + .menu-info__group {
      margin-top: 40px;
    }
    .subnav{
      position: relative;
      top: auto!important;
      left: auto;
      padding-left: 0;
      width: 100%;
      transform: none;
      display: none;
      visibility: visible;
      height: auto;
    }
    .menu-button_close{
      top: 55px;
      left: 55px;
      width: 52px;
    }
    .nav {
      margin-top: 40px;
    }
    .nav__item{
      padding: 0;
    }
    .nav__item-link{
      font-size: 2.2rem;
      line-height: 2.4rem;
      margin-bottom: 1.6rem;
      display: inline-block;
      svg{
        transform: scale(.8);
        top: 2px;
        margin-left: 10px;
        position: relative;
        transition: transform .3s ease-out;
      }
      &:hover{
        color: rgba(0,0,0,0.7);
      }
      &.active{
        svg{
          transform: scale(.8) rotate(90deg);
        }
      }
    }
    .nav__item:hover .nav__item-link:after{
      display: none;
    }
    .subnav__item{
      padding: 0;
    }
    .subnav__item-link{
      font-size: 1.8rem;
      line-height: 2rem;
    }
  }
  .wrapper{
    padding: 0 30px;
  }
  .page__title{
    margin-bottom: 25px;
  }
  .page__desc{
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 30px;
  }
  .card{
    width: calc(100% / 2 - 30px);
  }
  .documents__item{
    width: calc(100% / 2 - 30px);
  }
  .card__desc{
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .card__price{
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  //.section_about__slider{
  //  display: none;
  //}
  .section__left,
  .section__right{
    width: 100%;
  }
  .footer__row{
    flex-direction: column;
    text-align: center;
  }
  .footer__contacts{
    order: 1;
  }
  .footer__contacts-phone{
    margin-bottom: 10px;
    a{
      @include fontRegular;
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }
  .footer__contacts-mail{
    margin-bottom: 10px;
    a{
      @include fontRegular;
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }
  .footer__contacts-address{
    margin-bottom: 24px;
    a{
      @include fontRegular;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }
  .footer__center{
    order: 3;
  }
  .footer__nav{
    display: none;
  }
  .footer__nav-item{
    + .footer__nav-item{
      margin-left: 20px;
    }
  }
  .footer__nav-link{
    @include fontForum;
    font-size: 1.4rem;
    line-height: 1;
  }
  .footer__socials{
    margin-bottom: 18px;
  }
  .footer__social-link{
    @include fontRegular;
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .footer__disclamer{
    @include fontLight;
    font-size: 1.4rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: #C3C2C2;
  }
  .footer__links{
    width: 100%;
    order: 2;
    margin-bottom: 24px;
  }
  .footer__documents{
    text-align: center;
    margin-bottom: 47px;
    display: block;
  }
  .footer__documents-link{
    + .footer__documents-link{
      margin-top: 0;
      margin-left: 7px;
    }
  }
  .footer__creator{
    @include fontRegular;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .service__prices{
    columns: auto;
  }
  .service__price{
    padding-right: 0;
  }
  .service__price-group-name{
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  .service__price-group-list .service__price-name{
    font-size: 1rem;
    line-height: 1.4rem;
  }
  .service__price-value,
  .service__price-name{
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  .section_doctor-info{
    height: auto;
  }
  .doctor__info{
    display: flex;
    flex-direction: column;
    .doctor__info-name{
      order: 1;
    }
    .doctor__info-position{
      order: 2;
    }
    .doctor__info-experience{
      order: 3;
    }
    .doctor__info-scope{
      order: 5;
    }
    .section__visual{
      order: 4;
      position: relative;
      left: auto;
      bottom: auto;
    }
  }
  .about-useful__list{
    columns: 1;
  }
  .page_contacts{
    height: 100%;
    padding-bottom: 0;
    .section__row{
      flex-direction: column;
    }
  }
  .section_about__title{
    margin-bottom: 20px;
  }
  .doctor__info{
    min-height: auto;
  }
  .scroll-to-page__text{
    font-size: 2.2rem;
  }
  .section_main-screen,
  .section_about,
  .section_services{
    background-image: none;
  }
  .main-screen__scroll{
    display: none;
  }
  .section__visual{
    margin: 0 auto 32.89px auto;
  }
  .card {
    padding: 30px 50px 0 24px;
    margin: 0 auto 20px auto;
    height: 280px;

    &__title {
      margin-bottom: 10px;
      font-size: 1.8rem;
    }
    &__desc {
      margin-bottom: 0;
    }
    &__visual {
      img {
        //width: 192px;
        height: 192px;
      }
    }
  }

  .cards_large {
    .card {
      height: 280px;
    }
  }

  .main-screen__visual-wrap {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 768px) {
  .section__content{
    height: auto;
  }
  .card{
    width: calc(100% / 2 - 30px);
  }
  .documents__item{
    width: calc(100% / 2 - 30px);
  }
}
@media (max-width: 768px) and (orientation:landscape) {
  .main-screen__visual {
    display: none;
  }
}
@media (max-width: 640px), (max-width: 768px) and (orientation:landscape) {
  .page_index{
    position: relative;
    background-image: url("../images/svg/circle_bg-2.svg");
    background-repeat: no-repeat;
    background-position: top 150px right 100px;
    background-attachment: fixed;
  }
  .page{
    &.two_col {
      padding: 100px 0 0 0;
    }
  }
  .wrapper{
    padding: 0 30px;
  }
  //.page:not(.page_index) .section__content{
  //  padding-top: 100px;
  //}
  .header{
    padding: 35px 0 20px 0;
  }
  .header__phone{
    display: none;
  }
  .header__logo{
    width: 50px;
    height: 50px;
    left: calc(100% - 70px);
    top: 20px;
    transform: translateX(0);
  }
  .card{
    width: calc(100% - 30px);
  }
  .documents__item{
    width: calc(100% - 30px);
  }
  .main-screen__title{
    font-size: 4rem;
    margin-bottom: 25px;
    text-align: left;
  }
  .main-screen__text{
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: left;
  }
  .main-screen__visual-wrap{
    width: 150px;
    height: 150px;
  }
  .page.page_index .section_about .section__content {
    padding: 30px 0 0 0;
  }
  .page.page_about .section_about-index .section__content {
    padding: 0;
  }
  .section__content,
  .page:not(.page_index) .section__content {
    padding: 100px 0 0 0;
  }
  .section_about__subtitle{
    font-size: 2.8rem;
  }
  .doctor__info-name{
    font-size: 2.8rem;
  }
  .headline1{
    font-size: 4rem;
  }
  .headline2{
    font-size: 2.4rem;
  }
  .form__actions{
    flex-direction: column;
    .btn{
      flex: none;
      margin-bottom: 20px;
    }
    .form__confirmed{
      padding-left: 0;
    }
  }
  .contacts-info{
    flex-direction: column;
    margin-bottom: 30px;
  }
  .contacts-info__item{
    + .contacts-info__item{
      margin-top: 20px;
    }
  }
  .contacts__title{
    margin-bottom: 5px;
  }
  .contacts-info__item-value{
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
  .contacts-info__item_contacts{
    margin-left: 0;
  }
  .about-review__head{
    flex-direction: column;
    align-items: flex-start;
  }
  .about-review__name{
    font-size: 2.4rem;
    line-height: 3rem;
  }
  .about-review__date{
    font-size: 1.4rem;
    margin-left: 0;
  }
  .popup{
    padding: 20px 30px;
  }
  .form-review{
    flex-direction: column;
  }
  .form-review__rating{
    flex-direction: column;
    margin-bottom: 20px;
  }
  .form-review__rating-text{
    margin-right: 0;
    margin-bottom: 10px;
  }
  .form-review__tag{
    flex-direction: column;
    margin-bottom: 20px;
  }
  .form-review__tag-text{
    margin-right: 0;
    margin-bottom: 10px;
  }
  .menu .menu__left{
    padding: 50px 25px 30px 25px;
  }
  .menu .menu__right {
    padding: 30px 25px;
  }
  .menu .menu-button_close{
    top: 30px;
    left: 30px;
  }
  .section_about .section_about__content{
    padding-right: 0;
  }
  .section_about__desc{
    margin-bottom: 30px;
  }
  .doctor__info{
    .section__visual{
      img {
        height: auto;
      }
    }
  }
  .page_doctor-detail.page.two_col{
    background-size: 100%;
    background-position: top 90px right -24vw;
    padding-top: 90px;
    .wrapper{
      .section__content{
        padding-top: 0;
        padding-bottom: 55px;
      }
    }
    .section__title{
      font-size: 2.4rem;
      line-height: 2.6rem;
      margin-bottom: 0;
    }
  }
  .doctor__info-name{
    font-size: 1.8rem;
    line-height: 2rem;
    padding-right: 30%;
    margin-bottom: 9px;
  }
  .doctor__info-position{
    font-size: 1.1rem;
    line-height: 1.4rem;
    padding-right: 40%;
    margin-bottom: 4px;
  }
  .doctor__info-experience{
    font-size: 1.1rem;
    line-height: 1.4rem;
    padding-right: 40%;
    margin-bottom: 30px;
  }
  .section__visual{
    width: 280px;
    img{
      width: 200px;
    }
  }
  .doctor__info-scope{
    p{
      font-size: 1.2rem;
      line-height: 1.5rem;
      padding-right: 30%;
    }
    li{
      font-size: 1.2rem;
      line-height: 1.5rem;
      &:before{
        width: 3px;
        height: 3px;
        top: 6px;
      }
    }
  }
  .section__visual-circle{
    width: 280px;
    height: 140px;
    left: -15px;
    bottom: -5px;
  }
  .doctor__info,
  .doctor-education,
  .documents__list{
    padding-top: 32px;
  }
  .doctor-education__item + .doctor-education__item{
    margin-top: 15px;
  }
  .doctor-education__item-date{
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 2px;
  }
  .doctor-education__item-name{
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
  .documents__list{
    margin-top: -20px;
  }
  .documents__item{
    width: 178px;
    min-height: 225px;
    margin: 20px auto 0 auto;
    padding: 20px 20px 20px 30px;
  }
  .documents__item-organization{
    font-size: 1.1rem;
    line-height: 1.4rem;
    flex: 0 0 auto;
  }
  .documents__item-title{
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  .documents__item-desc{
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
  .documents__item-date{
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .section__head {
    flex-direction: column;
    align-items: flex-start;

    .section_services__title {
      margin-bottom: 20px;
    }
  }

  .cards {
    padding-bottom: 30px;
  }

  li {
    padding-left: 15px;

    &+li {
      margin-top: 15px;
    }
  }
  .section_about-slider{
    margin-top: 50px;
    padding-bottom: 100px;
  }
}
@media (max-width: 400px) {
  .section_about-slider{
    padding-bottom: 30px;
    .section__content, .page:not(.page_index) .section__content {
      padding: 0 !important;
    }

    .slider__item.swiper-slide-active .slider__item-title {
      opacity: 0 !important;
    }

    .slider__item-visual {
      height: 164px;
    }
  }
}
@media (min-width: 1921px) and (min-height: 1081px){
  .page_contacts {
    .section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100vh;

      .contacts__map {
        height: 500px;
      }
    }
  }
}

@media (min-width: 1921px) and (min-height: 1201px){
  .page_contacts {
    .section {
      .contacts__map {
        height: 600px;
      }
    }
  }
}

