.menu-sandwich {
  display: flex;
  align-items: center;

  &:hover{
    .menu-button{
      span{
        &:before{
          transform: translateX(-10px);
        }
      }
      &:before{
        transform: translateX(-20px);
      }
    }
  }
}

.menu-title {
  display: block;
  margin-left: 10px;
  color: #000000;
  font-size: 1.8rem;
  letter-spacing: 0.019rem;
  text-transform: uppercase;
  line-height: 1;
  white-space: nowrap;
}

.menu-button{
  display: block;
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 18px;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  span{
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: calc(50% - 1px);
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $baseDark;
      transition: transform .3s ease-out;
    }
  }
  &:after,
  &:before{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $baseDark;
    transition: all .3s;
  }
  &:after{
    top: 0;
  }
  &:before{
    bottom: 0;
    transition: transform .4s ease-out;
  }
}
.menu{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 73px 70px 72px 70px;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  transition: all .3s ease-out;
  &.active{
    opacity: 1;
    visibility: visible;
    .menu__overlay{
      background-color: rgba(0, 0, 0, .2);
      transition: background-color .2s .1s ease-out;
    }
    .menu__left{
      transform: translateX(0);
      transition: transform .45s ease-out;
    }
    .menu__right{
      transform: translateX(0);
      transition: transform .45s ease-out;
    }
    .menu-info{
      transform: translateX(0);
      opacity: 1;
      transition: transform .65s ease-out, opacity .21s .44s ease-out;
    }
    .nav__item{
      &:nth-child(1){
        transform: translateX(0);
        opacity: 1;
        transition: transform .65s ease-out, opacity .21s .44s ease-out;
      }
      &:nth-child(2){
        transform: translateX(0);
        opacity: 1;
        transition: transform .7s ease-out, opacity .26s .44s ease-out;

        .subnav {
          top: 3px;
        }
      }
      &:nth-child(3){
        transform: translateX(0);
        opacity: 1;
        transition: transform .75s ease-out, opacity .31s .44s ease-out;
      }
      &:nth-child(4){
        transform: translateX(0);
        opacity: 1;
        transition: transform .8s ease-out, opacity .36s .44s ease-out;
      }
      &:nth-child(5){
        transform: translateX(0);
        opacity: 1;
        transition: transform .85s ease-out, opacity .41s .44s ease-out;
      }
    }
  }
}
.menu__overlay{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .3s .3s ease-out;
}
.menu__content{
  background-color: $baseLight;
  height: 100%;
  display: flex;
}
.menu__left,
.menu__right{
  height: 100%;
  position: relative;
}
.menu__left{
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  background-color: rgb(249, 249, 249);
  padding: 95px 110px;
  transform: translateX(calc(-100% - 70px));
  transition: transform .3s ease-out;
}
.menu__right{
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  background-color: $baseGrayLight;
  padding: 70px 65px 70px 70px;
  transform: translateX(calc(100% + 70px));
  transition: transform .3s ease-out;
}
.menu-info{
  transform: translateX(100%);
  opacity: 0;
  transition: transform .3s ease-out, opacity .3s ease-out;
}
.menu-info__group{
  + .menu-info__group{
    margin-top: 40px;
  }
}
.menu-info__group-title{
  @include fontSemiBold;
  font-size: 1.8rem;
  line-height: 2.3rem;
  margin-bottom: 10px;
}
.menu-info__group-row{
  display: flex;
  .menu-info__group-field{
    + .menu-info__group-field{
      margin-top: 0;
      margin-left: 45px;
    }
  }
}
.menu-info__group-field{
  @include fontRegular;
  font-size: 1.8rem;
  line-height: 2.3rem;
  + .menu-info__group-field{
    margin-top: 28px;
  }
}


.menu__nav{

}
.nav{

}
.nav__item{
  padding: 20px 0;
  transition: transform .3s ease-out, opacity .3s ease-out;
  transform: translateX(-100%);
  opacity: 0;
  // @for $i from 2 to 10{
  //   &:nth-child(#{$i}){
  //     .subnav{
  //       top: 200px - ($i - 1) * 99px;
  //     }
  //   }
  // }
}
.nav__item:hover,
.nav__item.active{
  .nav__item-link{
    &:after{
      width: 100%;
    }
  }
}
.nav__item.js--menu-item-parent:hover,
.nav__item.js--menu-item-parent.active{
  .nav__item-link{
    &:after{
      transition: width 1s ease-in-out;
    }
  }
}
.nav__item-link{
  position: relative;
  @include fontForum;
  font-size: 3.2rem;
  line-height: 3.3rem;
  color: rgba(0, 0, 0, .7);
  transition: color .3s;
  &:hover{
    color: $textDark;
  }
  &:after{
    content: '';
    height: 2px;
    width: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $baseDark;
    transition: width .5s ease-out;
  }
}
.menu-button_close{
  position: absolute;
  top: 95px;
  left: 110px;
  width: 52px;
  height: 26px;
  &:after{
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    top: calc(50% - 1px);
    left: 100%;
    background-color: $baseDark;
    transition: width .3s ease-out;
  }
  &:hover{
    &:after{
      width: 30px;
    }
  }
}
.subnav{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 60px;
  left: 100%;
  width: 375px;
  // height: 400px;
  padding-left: 75px;
  transform: translateY(-50%);
  visibility: hidden;
  transition: visibility .3s;
  &.active{
    visibility: visible;
    .subnav__item{
      opacity: 1;
      transform: translateY(0);
      @for $i from 1 to 11{
        &:nth-child(#{$i}){
          transition: transform .6s $i * .03s ease-out, opacity .3s .35s + $i * .05s ease-out;
        }
      }
    }
  }
}
.subnav__item{
  padding: 10px 0;
  opacity: 0;
  line-height: normal;
  @for $i from 1 to 10{
    &:nth-child(#{$i}){
      transform: translateY($i * -50px);
      transition: transform .6s $i * .03s ease-out, opacity .3s ease-out;
    }
  }

  @media all and (max-width: 640px) {
    margin-bottom: 10px;
  }
}
.subnav__item-link{
  display: inline;
  position: relative;
  @include fontForum;
  font-size: 2.2rem;
  line-height: 2.2rem;
  border-bottom: 2px solid transparent;
  transition: border .3s ease-out;
  &:after{
    display: none;
    content: '';
    height: 2px;
    width: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $baseDark;
    transition: width .5s ease-out;
  }
  &:hover{
    border-bottom: 2px solid #000;
  }
  + .subnav__item-link{
    margin-top: 24px;
  }
}
