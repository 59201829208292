.documents__list{
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -40px;
}
.documents__item{
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% / 3 - 30px);
  min-height: 300px;
  //background-color: rgba(249, 249, 249, 1);
  background-image: linear-gradient(-135deg, transparent 25px, rgba(249, 249, 249, 1) 20px);;
  padding: 33px 26px 25px 40px;
  margin: 40px 15px 0 15px;
  box-shadow: -10px 10px 10px 0 rgba(0, 0, 0, .1);
  &:after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border: 18px solid transparent;
    border-bottom: 18px solid rgba(160, 158, 158, 0.1);
    border-left: 18px solid rgba(160, 158, 158, 0.1);
    box-shadow: -3px 3px 4px 0 rgba(160,158,158,0.15);
  }
}
.documents__item-organization{
  @include fontRegular;
  font-size: 1.4rem;
  line-height: 1.8rem;
  flex: 0 0 55px;
  color: #A5A3A3;
  margin-bottom: 20px;
}
.documents__item-content{
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.documents__item-title{
  @include fontForum;
  font-size: 3rem;
  line-height: 3.1rem;
  margin-bottom: 5px;
}
.documents__item-desc{
  @include fontRegular;
  font-size: 1.4rem;
  line-height: 1.9rem;
}
.documents__item-date{
  @include fontRegular;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #A09E9E;
}
.documents__item-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}