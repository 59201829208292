.page_doctors,
.page_doctor-detail{
  position: relative;
  background-image: url("../images/bg/circle@1x.png");
  //@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  //  background-image: url("../images/bg/circle@2x.png");
  //}
  background-repeat: no-repeat;
  background-position: top 150px right;
  background-attachment: fixed;
}
.section_doctor-info{
  .section__content{
    padding-bottom: 0;
  }
}
.doctor__info{
  position: relative;
  padding-right: 350px;
  min-height: 450px;
}
.section__visual{
  width: 480px;
  max-width: 100%;
  position: absolute;
  right: -110px;
  bottom: -60px;
  border-radius: 0 0 240px 240px;
  overflow: hidden;
  text-align: center;
  z-index: 0;
  font-size: 0;
  line-height: 0;
  img{
    max-width: 100%;
  }
}
.section__visual-circle{
  width: 480px;
  height: 240px;
  border-radius: 0 0 240px 240px;
  background-color: #F5F5F5;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  transform: rotate(-25deg);
  transform-origin: 50% 0;
  z-index: -1;
}
.doctor__info-name{
  @include fontForum;
  font-size: 3.9rem;
  line-height: 4rem;
  margin-bottom: 16px;
}
.doctor__info-position{
  @include fontRegular;
  font-size: 1.8rem;
  line-height: 2.3rem;
  margin-bottom: 5px;
}
.doctor__info-experience{
  @include fontRegular;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: #A09E9E;
  margin-bottom: 65px;
}
.doctor__info-scope{
  p{
    @include fontBold;
    font-size: 1.8rem;
    line-height: 2.3rem;
    margin-bottom: 18px;
  }
}
.doctor-education{

}
.doctor-education__list{

}
.doctor-education__item{
  + .doctor-education__item{
    margin-top: 30px;
  }
}
.doctor-education__item-date{
  @include fontRegular;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #828282;
  margin-bottom: 5px;
}
.doctor-education__item-name{
  @include fontRegular;
  font-size: 1.8rem;
  line-height: 2.4rem;
}