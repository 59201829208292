.page__nav{

}
.page__nav-item{
  + .page__nav-item{
    margin-top: 30px;
  }
}
.page__nav-link{
  position: relative;
  @include fontForum;
  font-size: 2.4rem;
  line-height: 2.6rem;
  &:after{
    content: '';
    height: 2px;
    width: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $baseDark;
    transition: width .5s ease-out;
  }
  &:hover,
  &.active{
    &:after{
      width: 100%;
    }
  }
}