.popup{
  display: none;
  width: 840px;
  max-width: 100%;
  padding: 60px 80px;
}
.popup__title{
  margin-bottom: 48px;
}
.popup__content{

}

.fancybox-bg{
  background-color: rgba(0,0,0,0.2);
}
.fancybox-is-open .fancybox-bg{
  opacity: 1;
}


.form-review{
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}
.form-review__rating{
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  .about-review__stars{
    margin-bottom: 0;
  }
}
.form-review__rating-text{
  @include fontRegular;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #A09E9E;
  margin-right: 12px;
}
.form-review__tag{
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.form-review__tag-text{
  @include fontRegular;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #A09E9E;
  margin-right: 12px;
}
.form-review-radios{
  label{
    cursor: pointer;
    + label{
      margin-left: 20px;
    }
    input{
      display: none;
      &:checked{
        + span{
          border-bottom: 1px solid #A09E9E;
          color: $textDark;
        }
      }
    }
    span{
      @include fontRegular;
      font-size: 1.8rem;
      line-height: 2.2rem;
      padding-bottom: 2px;
      border-bottom: 1px solid transparent;
      color: rgba(0, 0, 0, .7);
      text-transform: uppercase;
      transition: border .3s ease-out, color .3s ease-out;
    }
  }
}
.popup__close{
  position: absolute;
  top: 40px;
  right: 40px;
  opacity: .7;
  cursor: pointer;
  &:hover{
    opacity: 1;
  }
}