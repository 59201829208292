.page_contacts{
  position: relative;
  background-image: url("../images/svg/circle_bg-2.svg");
  background-repeat: no-repeat;
  background-position: top 150px right 100px;
  background-attachment: fixed;
  .section__row{
    align-items: flex-start;
  }
}

.contacts-info{
  display: flex;
  flex-wrap: wrap;
}
.contacts-info__item-row{
  display: flex;
  .contacts-info__item-value{
    + .contacts-info__item-value{
      margin-left: 35px;
    }
  }
}
.contacts-info__item{
  &_worktime{

  }
  &_contacts{
    margin-left: 60px;
  }
  &_address{
    width: 100%;
    margin-top: 60px;
  }
}
.contacts-info__item-value{
  font-size: 1.8rem;
  line-height: 2.4rem;
}
.contacts__title{
  @include fontForum;
  font-size: 2.4rem;
  line-height: 2.6rem;
  margin-bottom: 20px;
}
.contacts-form{

}
.contacts__map{
  width: 100%;
  height: 376px;
  filter: grayscale(100%);
}