.section_services{
  position: relative;
  background-image: url("../images/bg/bubbles@1x.png");
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url("../images/bg/bubbles@2x.png");
  }
  background-repeat: no-repeat;
  background-position: top 150px right;
  will-change: transform;
}
.section_services__list{

}

.section_services__title{
  margin-bottom: 50px;
}