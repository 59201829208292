$fontsUrl: '../fonts/';

@font-face {
  font-family:'Forum';
  src: url($fontsUrl + 'forum/subset-Forum.eot');
  src: url($fontsUrl + 'forum/subset-Forum.eot?#iefix') format('embedded-opentype'),
  url($fontsUrl + 'forum/subset-Forum.woff2') format('woff2'),
  url($fontsUrl + 'forum/subset-Forum.woff') format('woff'),
  url($fontsUrl + 'forum/subset-Forum.svg#subset-Forum') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'Source Sans Pro';
  src: url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Light.eot');
  src: url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
  url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Light.woff2') format('woff2'),
  url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Light.woff') format('woff'),
  url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family:'Source Sans Pro';
  src: url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Regular.eot');
  src: url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
  url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Regular.woff2') format('woff2'),
  url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Regular.woff') format('woff'),
  url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family:'Source Sans Pro';
  src: url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-SemiBold.eot');
  src: url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
  url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-SemiBold.woff2') format('woff2'),
  url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-SemiBold.woff') format('woff'),
  url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family:'Source Sans Pro';
  src: url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Bold.eot');
  src: url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
  url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Bold.woff2') format('woff2'),
  url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Bold.woff') format('woff'),
  url($fontsUrl + 'source_sans_pro/subset-SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@mixin fontForum{
  font-family: 'Forum', 'Verdana', 'Tahoma', 'Helvetica Neue', 'Arial', sans-serif ;
}
@mixin fontLight{
  font-family: 'Source Sans Pro', 'Verdana', 'Tahoma', 'Helvetica Neue', 'Arial', sans-serif ;
  font-weight: 300;
  font-style: normal;
}
@mixin fontRegular{
  font-family: 'Source Sans Pro', 'Verdana', 'Tahoma', 'Helvetica Neue', 'Arial', sans-serif ;
  font-weight: normal;
  font-style: normal;
}
@mixin fontSemiBold{
  font-family: 'Source Sans Pro', 'Verdana', 'Tahoma', 'Helvetica Neue', 'Arial', sans-serif ;
  font-weight: 600;
  font-style: normal;
}
@mixin fontBold{
  font-family: 'Source Sans Pro', 'Verdana', 'Tahoma', 'Helvetica Neue', 'Arial', sans-serif ;
  font-weight: bold;
  font-style: normal;
}

a{
  color: rgba(0, 0, 0, .7);
  text-decoration: none;
  transition: color .3s;
  &:hover{
    color: $textDark;
    text-decoration: none;
  }
  &.link_more{
    @include fontRegular;
    text-transform: uppercase;
    font-size: 1.3rem;
    color: $textLight;
  }
}
p{
  @include fontRegular;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 25px;
}
ul{
  list-style: none;
  margin-bottom: 25px;
}
li{
  position: relative;
  @include fontRegular;
  font-size: 1.8rem;
  line-height: 2.4rem;
  padding-left: 26px;
  &:before{
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $textDark;

    @media (max-width: 1024px) {
      top: 6px;
    }
  }
  + li{
    margin-top: 25px;
  }
}
.icon{
  display: inline-block;
  overflow: visible;
  +span{
    margin-left: 5px;
  }
  svg:not(:root) {
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
    width: 1.125em;
  }
}

.headline1{
  @include fontForum;
  font-size: 4.8rem;
  line-height: 1;
}
.headline2{
  @include fontRegular;
  font-size: 2.4rem;
  line-height: 3.2rem;
}
.headline3{
  @include fontForum;
  font-size: 3.2rem;
  line-height: 1;
}
.headline4{
  @include fontForum;
  font-size: 2.4rem;
  line-height: 1;
}
.signature{
  @include fontRegular;
  font-size: 1.2rem;
  line-height: 1;
}